/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { createRoot } from "react-dom/client"; // Updated import
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store, persistor } from './store/store'; // Ensure correct path
import { PersistGate } from 'redux-persist/integration/react';
import setAxiosDefaults from './api/axiosConfig'; // Ensure correct path
import App from "./App"; // Ensure correct path

// Setting up default Axios configurations
setAxiosDefaults();

// Find the root element
const rootElement = document.getElementById("root");
// Create a root
const root = createRoot(rootElement); // Create a root instance

// Render the React application using the root instance.
root.render(
  <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <BrowserRouter basename='/signIn'>
          <App />
        </BrowserRouter>
      </PersistGate>
  </Provider>
);
