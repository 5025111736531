import { createSlice } from '@reduxjs/toolkit';
import { FaTags, FaHashtag } from "react-icons/fa";

const TotalHCPs = 6490;

export const contentDashboardSlice = createSlice({
  name: 'contentEngagementDashboard',
  initialState: {
    selectedTag: 'Sentiment',
    selectedTagValue: 'Negative',
    Stages: [
      { Stage: "Awareness", Total: 10 },
      { Stage: "Consideration", Total: 4 },
      { Stage: "Education", Total: 3 },
      { Stage: "Adoption", Total: 2 },
      { Stage: "Advocacy", Total: 1 },
    ],
    count: [
      {
        today: "Total HCPs",
        title: TotalHCPs,
        persent: "+8%",
        icon: <FaTags size={25}/>,
        bnb: "bnb2",
      },
      {
        today: "Avg Engagement",
        title: "32%",
        persent: "+5%",
        icon: <FaHashtag size={25}/>,
        bnb: "bnb2",
      }
    ]
  },
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
    setSelectedTagValue: (state, action) => {
      state.selectedTagValue = action.payload;
    }
  }
});

export const { setSelectedTag, setSelectedTagValue } = contentDashboardSlice.actions;
export const selectSelectedTag = state => state.contentEngagementDashboard.selectedTag;
export const selectSelectedTagValue = state => state.contentEngagementDashboard.selectedTagValue;

export default contentDashboardSlice.reducer;
