import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";


import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

import 'antd/dist/antd.min.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {

  return (
    <div className="App">
      <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/sign-up" component={SignUp} />
          <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export default App;
