import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import settingsReducer from './slicers/settingsSlicers';
import overviewDataReducer from './slicers/overviewDataSlice';
import contentDashboardSlice from './slicers/contentDashboardSlicer'; 
import connectionDashboard from './slicers/connectionsDashboardSlicer';
import customerDashboard from './slicers/customerDashboardSlicer';
import cTapDashboardReducer from './slicers/cTapDashboard';
import contentEngagementDashboardSlicer from './slicers/contentEngagementDashboardSlicer';
import contentUsageReachDashboardSlicer from './slicers/contentUsageReachDashboardSlicer';

// Combining all reducers into one root reducer
const rootReducer = combineReducers({
    settings: settingsReducer,
    data: overviewDataReducer,
    contentDashboard: contentDashboardSlice,
    connectionDashboard: connectionDashboard,
    customerDashboard: customerDashboard,
    cTapDashboard: cTapDashboardReducer,
    contentEngagementDashboard: contentEngagementDashboardSlicer,
    contentUsageReachDashboard: contentUsageReachDashboardSlicer
});

// Configuring persistence for Redux state
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['settings', 'cTapDashboard'] // Now cTapDashboard state will also persist
};

// Creating persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuring the store with middleware and the persisted reducer
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});

// Creating the persistor for the store
export const persistor = persistStore(store);
