import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from './axiosConfig';

export const fetchData = createAsyncThunk('cTapDashboard/fetchData', async (endpoint, { dispatch, getState }) => {
  try {
    const response = await axiosInstance.get(`/${endpoint}`);
    const data = response.data;
    console.log(data)
    if (endpoint === 'document_tags') {
      let parentChild = {};
      console.log(data.document_tags)
      Object.keys(data.document_tags).forEach(docId => {
        const documentKey = data.document_tags[docId].document_key;
        
        // Handle documents with multiple pages
        if (documentKey.includes("/pages/")) {
          const [basePath, pagePart] = documentKey.split("/pages/");
          const pageNum = pagePart.split("_page")[1]; // Extract page number
          const pathParts = basePath.split("/");
          const docName = pathParts[pathParts.length - 1];

          // Ensure parentChild[docName] exists
          if (!parentChild[docName]) {
            parentChild[docName] = { pages: {} }; // Initialize as an object with a pages property
          }

          // Ensure parentChild[docName]["pages"] exists
          if (!parentChild[docName]["pages"]) {
            parentChild[docName]["pages"] = {};
          }

          // Add the document tag to the correct page number
          parentChild[docName]["pages"][pageNum] = data.document_tags[docId];

        } else {
          // Handle documents without multiple pages
          const pathParts = documentKey.split("/");
          const docName = pathParts[pathParts.length - 1];

          // Ensure parentChild[docName] exists
          if (!parentChild[docName]) {
            parentChild[docName] = data.document_tags[docId];
          } else {
            // Merge the existing document tag with new one
            Object.assign(parentChild[docName], data.document_tags[docId]);
          }
        }
      });

      return parentChild;
    }

    return data;

  } catch (error) {
    console.error('Error in fetchData:', error);
    throw error; // Re-throw to propagate the error to Redux
  }
}, {
  serializeError: (error) => error.message || 'Unknown error'
});



export const createData = createAsyncThunk('cTapDashboard/createData', async ({ endpoint, data }) => {
  const response = await axiosInstance.post(`/${endpoint}`, data);
  return response.data;
});

export const updateData = createAsyncThunk(
  'data/update',
  async ({ endpoint, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/${endpoint}`, data);

      return response.data;
    } catch (error) {
      console.error('Error updating data:', error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk('cTapDashboard/deleteData', async ({ endpoint, data }) => {
  const response = await axiosInstance.delete(`/${endpoint}`, { data });
  return response.data;
});

export const updateDocumentTag = createAsyncThunk(
    'cTapDashboard/updateDocumentTag',
    async ({ documentId, tagKey, newValue }, thunkAPI) => {
      const response = await axiosInstance.put(`/document_tags`, {
        [tagKey]: newValue,
        document_id: documentId
      });
      return {
        documentId,
        tagKey,
        newValue: response.data
      };
    }
  );
  

