import { createSlice } from '@reduxjs/toolkit';
import { FaUsers, FaLightbulb, FaShieldVirus, FaMoneyBillTransfer, FaPersonBooth, FaChartLine, FaImagePortrait, FaUserDoctor, FaFileImage, FaTag, FaCloudArrowDown, FaCloudArrowUp } from "react-icons/fa6";

export const overviewDataSlice = createSlice({
  name: 'data',
  initialState: {
    admin: [
      {
        today: "Total Users",
        title: "100",
        icon: <FaUsers size={25}/>,
        bnb: "bnb2",
        link: "/user-management",
        hiddenText: "Manage Users"
      },
      {
        today: "Enabled Products",
        title: "4",
        persent: "",
        icon: <FaLightbulb size={25}/>,
        bnb: "bnb2",
        link: "/products",
        hiddenText: "Manage Products"
      },
      {
        today: "Network Security",
        title: "VPC",
        persent: "enabled",
        icon: <FaShieldVirus size={25}/>,
        bnb: "bnb2",
        link: "/security",
        hiddenText: "Manage Network"
      },
      {
        today: "Current Bill",
        title: "$100",
        persent: "-10%",
        icon: <FaMoneyBillTransfer size={25}/>,
        bnb: "bnb2",
        link: "/billing",
        hiddenText: "Manage Billing"
      }
    ],
    engagement: [
        {
          today: "Impressions this Week",
          title: "4877",
          persent: "+10%",
          icon: <FaPersonBooth size={25}/>,
          bnb: "bnb2",
          link: "/engagement-dashboard",
          hiddenText: "Engagement Metrics",
        },
        {
          today: "Active Dashboards",
          title: "10",
          icon: <FaChartLine size={25}/>,
          bnb: "bnb2",
          link: "/engage-iq-deployments",
          hiddenText: "Dashboard List",
        }
    ],
    customer: [
      {
        today: "Customer Profiles",
        title: "343,534",
        persent: "-5%",
        icon: <FaImagePortrait size={25}/>,
        bnb: "redtext",
        link: "/customer-profiles",
        hiddenText: "View Profiles",
      },
      {
        today: "Segments",
        title: "40",
        persent: "+3%",
        icon: <FaUserDoctor size={25}/>,
        bnb: "bnb2",
        link: "/segments",
        hiddenText: "Manage Segments"
      }
    ],
    content: [
      {
        today: "Active Creatives",
        title: "3901",
        persent: "-21%",
        icon: <FaFileImage size={25}/>,
        bnb: "redtext",
        link: "/content-dashboard",
        hiddenText: "View Content Metrics"
      },
      {
        today: "New Tags this Week",
        title: "10",
        persent: "+1%",
        icon: <FaTag size={25}/>,
        bnb: "bnb2",
        link: "https://demo.catalystiqs.com",
        hiddenText: "Tagging Application"
      }
    ],
    connect: [
      {
        today: "Active Data Sources",
        title: "26",
        persent: "-20%",
        icon: <FaCloudArrowDown size={25}/>,
        bnb: "redtext",
        link: "/connections",
        hiddenText: "Manage Connections"
      },
      {
        today: "Active Activations",
        title: "6",
        persent: "+20%",
        icon: <FaCloudArrowUp size={25}/>,
        bnb: "bnb2",
        link: "/activations",
        hiddenText: "Manage Activations"
      }
    ],
  reducers: {
    // Define reducers to manipulate the state if needed
  }
}
});

export default overviewDataSlice.reducer;
