import { createSlice } from '@reduxjs/toolkit';
import { FaTags, FaHashtag } from "react-icons/fa";

const TotalImpressions = 4810;
const TotalReach = 890;

export const contentUsageReachDashboardSlice = createSlice({
  name: 'contentUsageReachDashboard',
  initialState: {
    selectedTag: "Sentiment",
    count: [
      {
        today: "Total Impressions",
        title: TotalImpressions,
        persent: "+12%",
        icon: <FaTags size={25}/>,
        bnb: "bnb2",
      },
      {
        today: "Total Reach",
        title: TotalReach,
        persent: "+5%",
        icon: <FaHashtag size={25}/>,
        bnb: "bnb2",
      }
    ]
  },
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    }
  }
});

export const { setSelectedTag } = contentUsageReachDashboardSlice.actions;
export const selectSelectedTag = state => state.contentUsageReachDashboard.selectedTag;

export default contentUsageReachDashboardSlice.reducer;