import { createSlice, createSelector } from '@reduxjs/toolkit';
import { fetchData, createData, updateData, deleteData } from '../thunks/thunks';

const initialState = {
  data: {
    tags: {},
    document_tags: {},
    prediction_values: {}, // Assuming you have prediction values
  },
  selectedTagValue: '',
  tempTags: {}, // Initial state for temporary tags
  tempTagValue: {}, // Initial state for temporary tag values
  loading: false,
  error: null,
};

const cTapDashboardSlice = createSlice({
  name: 'cTapDashboard',
  initialState,
  reducers: {
    addTempTag: (state, action) => {
      state.tempTags[action.payload.id] = action.payload;
    },
    removeTempTag: (state, action) => {
      delete state.tempTags[action.payload];
    },
    addTempTagValue: (state, action) => {
      const { id, value_name, parent_tag_ids, description } = action.payload;
      state.tempTagValue = { id, value_name, parent_tag_ids, description };
      console.log(state.tempTagValue)
    },
    removeTempTagValue: (state, action) => {
      const { tagValueId } = action.payload;
      console.log(tagValueId)
        delete state.tempTagValues;
      },
    updateDocumentTagLocally: (state, action) => {
      const { documentId, tagKey, newValue } = action.payload;
      if (state.data.document_tags[documentId]) {
        state.data.document_tags[documentId][tagKey] = newValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data[action.meta.arg] = action.payload;
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch data';
        state.loading = false;
      })
      .addCase(createData.pending, (state) => {
        state.loading = true;
      })
      .addCase(createData.fulfilled, (state, action) => {
        state.data[action.meta.arg] = {
          ...state.data[action.meta.arg],
          [action.payload.id]: action.payload,
        };
        state.loading = false;
      })
      .addCase(createData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(updateData.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.data[action.meta.arg] = {
          ...state.data[action.meta.arg],
          [action.payload.id]: action.payload,
        };
        state.loading = false;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(deleteData.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteData.fulfilled, (state, action) => {
        const { [action.meta.arg]: _, ...rest } = state.data[action.meta.arg];
        state.data[action.meta.arg] = rest;
        state.loading = false;
      })
      .addCase(deleteData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { addTempTag, removeTempTag, addTempTagValue, removeTempTagValue, updateDocumentTagLocally } = cTapDashboardSlice.actions;

export const selectDocumentTagsData = createSelector(
  (state) => state.cTapDashboard.data.document_tags,
  (documentTagsData) => {
    const formattedData = {};
    console.log('Document Tags Data:', documentTagsData);
    Object.keys(documentTagsData).forEach(docName => {
      if (documentTagsData[docName].pages) {
        // It's a multi-page document
        console.log("PAGES!!!!!!!!!!!!")
        formattedData[docName] = {
          ...documentTagsData[docName],
          isMultiPage: true,
        };
      } else {
        // Single-page document
        formattedData[docName] = {
          ...documentTagsData[docName],
          isMultiPage: false,
        };
      }
    });

    console.log('Formatted Document Tags Data:', formattedData);
    return formattedData;
  }
);

export const selectTagsData = createSelector(
  (state) => state.cTapDashboard.data.tags,
  (tagsData) => tagsData
);

export const selectSelectedTagValue = createSelector(
  (state) => state.cTapDashboard.SelectedTagValue,
  (selectedTagValue) => selectedTagValue
);

export const selectTempTagValue = createSelector(
  (state) => state.cTapDashboard.tempTagValue,
  (tempTagValue) => tempTagValue
);

export const selectPredictedData = createSelector(
  (state) => state.cTapDashboard.data.prediction_values,
  (predictionsData) => predictionsData
);

export default cTapDashboardSlice.reducer;
