import axios from 'axios';

let API_URL = '';

if (window.location.href.includes('localhost')) {
  API_URL = 'http://localhost:3001';
} else {
  API_URL = '/api';
}

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(config => {
    config.maxRedirects = 0;
    return config;
  });

axiosInstance.interceptors.response.use(
    response => response,
    error => {
      // Check if the error response is an expired session
      if (error.response && (error.response.status === 401 || error.response.status === 302)) {
        console.log('refreshed page')
        window.location = '/';
      }
      return Promise.reject(error);
    }
  );

export default axiosInstance;
