// src/features/settings/settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    admin: true,
    engageIQ: true,
    customerIQ: true,
    contentIQ: true,
  },
  reducers: {
    toggleAdmin: state => {
      state.admin = !state.admin;
    },
    toggleEngageIQ: state => {
      state.engageIQ = !state.engageIQ;
    },
    toggleCustomerIQ: state => {
      state.customerIQ = !state.customerIQ;
    },
    toggleContentIQ: state => {
      state.contentIQ = !state.contentIQ;
    }
  }
});

export const { toggleAdmin, toggleEngageIQ, toggleCustomerIQ, toggleContentIQ } = settingsSlice.actions;

export default settingsSlice.reducer;
